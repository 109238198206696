import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "react-seo-component";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Card, Layout } from "../components";
import { Container, Flex, Title } from "../components/styles";

export default function ProjectsPage({ data, location }) {
  const {
    title,
    description,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();

  const { pathname } = location;
  // console.log(pathname);

  const showTemplate = false;

  return (
    <Layout pathname={pathname}>
      <Container>
        <Seo
          title={`Home`}
          titleTemplate={title}
          description={description}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
        />

        {showTemplate && <p>This is using src/pages/projects.js</p>}

        <section>
          <Title>Projects</Title>
          <Flex>
            {data.projects.nodes.map(({ id, excerpt, frontmatter, slug }) => {
              const featuredImage = frontmatter?.featureImage
                ? getImage(
                    frontmatter.featureImage.childImageSharp.gatsbyImageData
                  )
                : null;
              return (
                <Card
                  key={id}
                  link={`/projects/${slug}`}
                  image={featuredImage && featuredImage}
                  heading={frontmatter.title}
                  excerpt={excerpt}
                />
              );
            })}
          </Flex>
        </section>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query PROJECTS_PAGE_QUERY {
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "/src/projects/" } }
    ) {
      nodes {
        id
        slug
        excerpt
        frontmatter {
          slug
          title
          featureImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
  }
`;
